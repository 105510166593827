import React from "react";
import Hero from "../hero/Hero";
import Navbar from "./Navbar";
import "./Header.scss";

const Header = () => {
  return (
    <header className="header" id="home">
      <Navbar/>
      <Hero />
    </header>
  );
};

export default Header;
