import React from "react";
import "./Video.scss";
import Iframe from "react-iframe"

const Video = () => {
  return (
    <section className="video" id="video">
      <div className="container">
        <div className="video__content">
          <h2 className="video__title">VIDEONI ALBATTA KO’RING</h2>
          <div className="video__iframe">
          <Iframe
              url="https://www.youtube.com/embed/OziTdFRiThI"
              width="640px"
              height="320px"
              id=""
              frameborder="0"
              className=""
              display="block"
              position="relative"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
