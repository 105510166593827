import React from 'react'

const HeroButton = ({text,url}) => {
  return (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a href={url} className='hero__button'>
        {text}
    </a>
  )
}

export default HeroButton