import About from "./components/about/About";
import Directions from "./components/directions/Directions";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Partners from "./components/partners/Partners"
import Specialists from "./components/specialists/Specialists";
import Video from "./components/video/Video";

function App() {
  return (
    <div className="app">
        <Header/>
        <main>
          <Directions/>
          <About/>
          <Video/>
          <Specialists/>
          <FAQ/>
          <Partners/>
        </main>

        <Footer/>
    </div>
  );
}

export default App;
